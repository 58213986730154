<template>
  <v-card
    min-height="240px"
    outlined
    flat
    fluid
    :loading="isLoadingLink"
  >
    <v-snackbar
      v-model="enableAlert"
      color="error"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="enableAlert = false"
        >
          <v-icon color="white">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <div
      align="center"
      class="mt-4"
    >
      <v-icon
        size="64px"
      >
        {{ icon }}
      </v-icon>
    </div>

    <v-card-title
      class="justify-center"
      style="word-break: break-word"
    >
      {{ title }}
    </v-card-title>

    <v-card-subtitle class="justify-center">
      {{ description }}
    </v-card-subtitle>

    <v-card-actions>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in downloadLinks"
            :key="index"
            @click="downloadInstaller(item)"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import axios from 'axios';

import { mdiClose, mdiDownload } from '@mdi/js';

export default {
  name: 'Tool',
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    icon: { type: String, required: true },
    downloadLinks: { type: Array, required: true },
  },
  data() {
    return {
      isLoadingLink: false,
      icons: { mdiClose, mdiDownload },
      enableAlert: false,
      alertMessage: this.$t('deconve.downloadToolInstallerError'),
    };
  },
  methods: {
    downloadFile(installerUrl) {
      // to mimick the user click
      const link = document.createElement('a');

      document.body.appendChild(link);
      link.href = installerUrl;
      link.setAttribute('type', 'hidden');
      link.click();
    },
    downloadInstallerFromNewRoute(url) {
      return new Promise((resolve) => {
        // We do not have an appstorage in development
        const host = 'https://appstorage.deconve.com/v1';
        const infoFileUrl = `${host}/${url}/info.json`;

        axios.get(infoFileUrl).then((response) => {
          const { data } = response;

          if (data?.installer_name) {
            const { installer_name: installerName } = data;
            const installerUrl = `${host}/${url}/${installerName}`;

            this.downloadFile(installerUrl);
            resolve(true);
          } else {
            resolve(false);
          }
        }).catch(() => resolve(false));
      });
    },
    downloadInstallerFromOldRoute(oldUrl) {
      return new Promise((resolve) => {
        // We do not have an appstorage in development
        const host = 'https://appstorage.deconve.com/v1';
        const installerUrl = `${host}/${oldUrl}`;

        this.downloadFile(installerUrl);
        resolve();
      });
    },
    downloadInstaller({ url, oldUrl }) {
      this.isLoadingLink = true;

      this.downloadInstallerFromNewRoute(url).then((success) => {
        if (success) {
          this.isLoadingLink = false;
        } else if (oldUrl) {
          this.downloadInstallerFromOldRoute(oldUrl).then(() => {
            this.isLoadingLink = false;
          });
        } else {
          this.enableAlert = true;
          this.isLoadingLink = false;
        }
      });
    },
  },
};
</script>
