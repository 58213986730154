<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <div
      style="height: 100%"
      :style="{
        borderColor: $vuetify.theme.themes.light.border,
        backgroundColor: $vuetify.theme.themes.light.background,
      }"
    >
      <div class="pa-2">
        <div
          class="d-flex flex-wrap justify-center"
        >
          <v-col
            v-for="item in availableTools"
            :key="item.id"
            class="pa-1"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            :data-cy="item.id"
          >
            <tool
              :title="item.title"
              :description="item.description"
              :icon="item.icon"
              :download-links="item.downloadLinks"
            />
          </v-col>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import {
  mdiAccountDetails as retailAnalyticsIcon,
  mdiAccountMultiple as peopleFlowIcon,
  mdiCar as vehicleFlowIcon,
  mdiFaceRecognition as faceIdIcon,
} from '@mdi/js';

import Tool from './Tool.vue';

export default {
  name: 'Tools',
  components: {
    Tool,
  },
  computed: {
    availableTools() {
      const ubuntunOnlineInstaller = this.$t('deconve.ubuntunOnlineInstaller');
      const windowsOnlineInstaller = this.$t('deconve.windowsOnlineInstaller');

      return [
        {
          id: 'com.deconve.id',
          title: this.$t('deconve.apps.id'),
          description: this.$t('deconve.apps.idDescription'),
          icon: faceIdIcon,
          downloadLinks: [
            {
              url: 'spid_faceid/latest/ubuntu_18_04_x86_64',
              oldUrl: 'faceid/ubuntu_18_04_x86_64_online/',
              name: ubuntunOnlineInstaller,
            },
            {
              url: 'spid_faceid/latest/windows_x86_64',
              oldUrl: 'faceid/windows_x86_64_online/',
              name: windowsOnlineInstaller,
            },
          ],
        },
        {
          id: 'com.deconve.analytics.peopleflow',
          title: this.$t('deconve.apps.peopleFlowAnalytics'),
          description: this.$t('deconve.apps.peopleFlowAnalyticsDescription'),
          icon: peopleFlowIcon,
          downloadLinks: [
            {
              url: 'spid_people_counter/latest/ubuntu_18_04_x86_64',
              oldUrl: 'people_counter/ubuntu_18_04_x86_64_online/',
              name: ubuntunOnlineInstaller,
            },
            {
              url: 'spid_people_counter/latest/windows_x86_64',
              oldUrl: 'people_counter/windows_x86_64_online/',
              name: windowsOnlineInstaller,
            },
          ],
        },
        {
          id: 'com.deconve.analytics.vehicleflow',
          title: this.$t('deconve.apps.doohAudicenceAnalytics'),
          description: this.$t('deconve.apps.doohAudicenceAnalyticsDescription'),
          icon: vehicleFlowIcon,
          downloadLinks: [
            {
              url: 'spid_vehicles_counter/latest/ubuntu_18_04_x86_64',
              oldUrl: 'vehicles_counter/ubuntu_18_04_x86_64_online/',
              name: ubuntunOnlineInstaller,
            },
            {
              url: 'spid_vehicles_counter/latest/windows_x86_64',
              oldUrl: 'vehicles_counter/windows_x86_64_online/',
              name: windowsOnlineInstaller,
            },
          ],
        },
        {
          id: 'com.deconve.analytics.retail',
          title: this.$t('deconve.apps.retailAnalytics'),
          description: this.$t('deconve.apps.retailAnalyticsDescription'),
          icon: retailAnalyticsIcon,
          downloadLinks: [
            {
              url: 'spid_retail_analytics/latest/ubuntu_18_04_x86_64',
              oldUrl: 'retail_analytics/ubuntu_18_04_x86_64_online/',
              name: ubuntunOnlineInstaller,
            },
            {
              url: 'spid_retail_analytics/latest/windows_x86_64',
              oldUrl: 'retail_analytics/windows_x86_64_online/',
              name: windowsOnlineInstaller,
            },
          ],
        },
        {
          id: 'com.deconve.faceMatch',
          title: this.$t('deconve.apps.faceMatch'),
          description: this.$t('deconve.apps.faceMatchDescription'),
          icon: faceIdIcon,
          downloadLinks: [
            {
              url: 'spid_face_match/latest/ubuntu_18_04_x86_64',
              name: ubuntunOnlineInstaller,
            },
            {
              url: 'spid_face_match/latest/windows_x86_64',
              name: windowsOnlineInstaller,
            },
          ],
        },
      ];
    },
  },
};
</script>
